import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(
    private toastr: ToastrService
  ) { }

  /**
   * @description show a notification with timeout
   */
  showInfoWithTimeout( message, title, timespan ){
    this.toastr.info( message, title, {
      timeOut:  timespan,
      progressBar: true,
      progressAnimation: 'decreasing'
    })
  }

  /**
   * @description show a success notification
   */
  showSuccessWithTimeout( message, title, timespan ){
    this.toastr.success( message, title, {
      timeOut:  timespan,
      progressBar: true,
      progressAnimation: 'decreasing'
    })
  }

  /**
   * @description show a warning notification
   */
  showWarningWithTimeout( message, title, timespan ){
    this.toastr.warning( message, title, {
      timeOut:  timespan,
      positionClass: 'toast-bottom-right',
      progressBar: true,
      progressAnimation: 'decreasing'
    });
  }


  /**
   * @description show a danger notification
   */
  showDangerWithTimeout( message, title, timespan ){
    this.toastr.error( message, title, {
      timeOut:  timespan,
      positionClass: 'toast-bottom-right',
      progressBar: true,
      progressAnimation: 'decreasing'
    })
  }

}
