import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';
import { User } from '../../_models/User';
import * as cryptoJS from "crypto-js";
import * as jwt_decode from 'jwt-decode';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private readonly EXPIRES_AT = 'EXPIRES_AT';
  private isLogged: BehaviorSubject<boolean>
  private httpOptions: any;
  WEB_URL: string = environment.serverUrl;


  constructor(
    private http: HttpClient,
    private _router: Router
  ) {

    try {
      if (localStorage.getItem(this.JWT_TOKEN)) {
        this.isLogged = new BehaviorSubject<boolean>(true);
      } else {
        this.isLogged = new BehaviorSubject<boolean>(false);
      }
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
    } catch (Exception) { }
  }

  login(data: User): Observable<any> {
    return this.http.post(this.WEB_URL + '/login', data, this.httpOptions).pipe(tap((tokens) => {
      this.setSession(tokens);
    }));
  }

  refreshToken() {
    return this.http.post<any>(`${this.WEB_URL}/refreshToken`, {
      'refreshToken': this.getRefreshToken()
    }).pipe(tap((tokens) => {
      this.setSession(tokens);
    }));
  }

  logout() {
    var _this = this;
    return this.http.post(`${this.WEB_URL}/logout`, {})
      .subscribe(res => {
        _this.removeTokens();
        _this._router.navigate(['/'])
        _this.isLogged.next(false)
      }, err => {
        _this.removeTokens();
        _this._router.navigate(['/login'])
        _this.isLogged.next(false)
      });
  }

  register(data: User): Observable<any> {
    return this.http.post(this.WEB_URL + '/register', data, this.httpOptions);
  }

  contactUs(data: any): Observable<any> {
    return this.http.post(this.WEB_URL + '/contact-us', data, this.httpOptions);
  }

  getUser(data: any): Observable<any> {
    return this.http.post(this.WEB_URL + '/get-user', data, this.httpOptions);
  }

  /**
  * @param {String} email get the code to validate email
  * @description consumes validate email API
  * @returns status
  */

  //Peticion POST  para generar el codigo otp
  generateOtp(email: any): Observable<any> {
    return this.http.post(this.WEB_URL + '/generate-mail-otp', email, this.httpOptions);
  }

  //peticion POST para validar el codigo otp
  validateOtp(email: string, code: string): Observable<any> {
    return this.http.post(this.WEB_URL + '/validate-mail-otp', { email, code }, this.httpOptions);
  }

  /**
  * @param {String} code get the code to validate email
  * @description consumes validate email API
  * @returns status
  */
  validateEmail(code: any): Observable<any> {
    return this.http.post(this.WEB_URL + '/validate-email', code, this.httpOptions);
  }

  /**
  * @param {String} email get the email to send the new password
  * @description consumes restore password API
  * @returns email
  */
  forgotPassword(email: any): Observable<any> {
    return this.http.post(this.WEB_URL + '/forgot-password', email, this.httpOptions);
  }

  recoverPassword(form: any): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(this.WEB_URL + '/recover-password', form, this.httpOptions);
  }

  /**
 * @param {String} code get the code to recover pasword
 * @description consumes code recover password API
 * @returns status
 */
  validateCodeRecoverPassword(code: any): Observable<any> {
    return this.http.post(this.WEB_URL + '/validate-code-recover-password', code, this.httpOptions);
  }

  rememberMe(email: string, password: string) {
    const user = cryptoJS.AES.encrypt(JSON.stringify({ email, password }), environment.variable);
    localStorage.setItem('user', user);
  }

  rememberUser() {
    const user = localStorage.getItem('user');
    if (user !== null) {
      var bytes = cryptoJS.AES.decrypt(user.toString(), environment.variable);
      var decryptedData = JSON.parse(bytes.toString(cryptoJS.enc.Utf8));
      return decryptedData;
    }
    else {
      return null;
    }
  }

  dontRemember() {
    localStorage.removeItem('user');
    localStorage.removeItem('currentUser');
  }

  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  isLoggedIn(): BehaviorSubject<boolean> {
    return this.isLogged
  }

  isAuthorized(allowedRoles: string[]): boolean {
    if (allowedRoles == null || allowedRoles.length === 0) {
      return true;
    }
    const token = this.getJwtToken();

    if (token) {
      const decodeToken = jwt_decode(token);
      if (!decodeToken) {
        this.removeTokens();
        return false;
      }

      //Validar si el token ya expiró
      if (Date.now() >= decodeToken.exp * 1000) {
        this.removeTokens();
        return false;
      }

      return allowedRoles.some(role => {
        return decodeToken['data']['roles'].includes(role.toLowerCase());
      });
    }
    return false;
  }

  //peticion POST para validar el codigo otp
  validateUnlockOtp(id:number, code:string): Observable<any> {
    return this.http.post(this.WEB_URL + '/validate-unlock-otp', { id, code }, this.httpOptions);
  }

  //envía POST para reenviar código de recuperación a usuario.
  sendRecoverMail(email): Observable<any> {
    return this.http.post(this.WEB_URL + '/send-recover-mail', { email }, this.httpOptions);
  }

  saveTermsAndConditions(user_id:number){
    return this.http.post(this.WEB_URL + '/save-terms-conditions-agreement', { user_id }, this.httpOptions);
  }


  private removeTokens() {
    this.isLogged.next(false);
    localStorage.removeItem('currentUser');
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    localStorage.removeItem(this.EXPIRES_AT);
  }

  private setSession(authResult) {
    this.isLogged.next(true);
    const expiresAt = moment(authResult.expires_at)
    localStorage.setItem(this.JWT_TOKEN, authResult.token);
    localStorage.setItem(this.REFRESH_TOKEN, authResult.refreshToken);
    localStorage.setItem(this.EXPIRES_AT, JSON.stringify(expiresAt.valueOf()));
  }
}
