import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable, BehaviorSubject, throwError } from "rxjs";

import { AuthenticationService } from "../_services/authentication/authentication.service";
import { NotificationsService } from "../_services/notifications/notifications.service";
import { catchError, switchMap, filter, take } from "rxjs/operators";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    public AuthenticationService: AuthenticationService,
    private notifyService: NotificationsService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.AuthenticationService.getJwtToken()) {
      if (!request.url.includes('https://api.ipify.org')) {
        request = this.addToken( 
          request, this.AuthenticationService.getJwtToken()
        );
      }
    }

    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next, error);
        } else if (error instanceof HttpErrorResponse && error.status === 408) {
          this.isRefreshing = false;
          this.notifyService.showWarningWithTimeout(
            "Tu sesión ha caducado, inicia sesión nuevamente",
            "Alerta",
            7000
          );
          this.AuthenticationService.logout();
        }
        return throwError(error);
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler, error) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.AuthenticationService.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.token);
          return next.handle(this.addToken(request, token.token));
        })
      );
    } else {
      // return throwError(error)
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addToken(request, token));
        })
      );
    }
  }
}
