import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IntroComponent } from './home/intro/intro.component';

import { NopagefoundComponent } from './structure/nopagefound/nopagefound.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'login', loadChildren: () => import('./authentication/auth.module').then(m => m.AuthModule) },
  { path: 'register', loadChildren: () => import('./authentication/auth.module').then(m => m.AuthModule) },
  { path: 'customer', loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
  { path: 'backoffice', loadChildren: () => import('./backoffice/backoffice.module').then(m => m.BackofficeModule) },
  { path: '404', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: '**', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
